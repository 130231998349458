import React from 'react';
import JobTransferEdit from './JobTransferEdit/JobTransferEdit';
import './EditOperation.scss';

export default function EditOperation({ plant, job, onJobChanges,receiveData, destinationConfiguration }) {  
    const modalRef = React.useRef();

    let jobToEdit = {
        id: job.id,
        startDate: job.startDate,
        endDate: job.endDate,
        topic: job.topic,
        type: job.typeName,
        destinationTopic: job.destinationItemTopic,
        destinationType: job.destinationTypeName,
        data: job.data,
        configuration: job.configuration,
        jobTypeId: job.destinationTypeId,        
        parentJobId: job.parentJobId,
        plantId: plant.value,
        plantName: plant.label,
        relationship: job.relationship,
    };

    return (
        <div className="transfer-operation-edit">
            <div className="transfer-operation-edit-header">
                <h3>Edit operation:&nbsp;{job.typeName}</h3><br></br>
                <h3>To:&nbsp;{plant.label}</h3>
            </div>
            <div className="form-container">
                <JobTransferEdit
                    modalRef={modalRef}
                    editItem={jobToEdit}
                    plant={plant}
                    onJobChanges={onJobChanges}
                    sendData={receiveData}
                    destinationConfiguration={destinationConfiguration}
                />
            </div>
        </div>
    );
}